.success {
	padding:2rem;
	
}
.content figure:not(:first-child) {
	margin-top: 0em;
}

$image: '../assets/images/background.png';
$opa: 0.75;
@media only screen and (min-width: 320px) {
	.body__background {
	  //ackground-color: lightgreen;
		background-image: url($image) !important;
		background-size: cover;
		opacity: 1;
		width: 100%;
		height: 100%;
	}
	.boxSize{
		max-width: 270px;
		height: 425px;
	}
	.box{
		//color: #F4F4F4;
		background-color: rgba(248, 248, 248, $opa);
		box-shadow: 2px,2px;
		//opacity: 0.6;		
	}
	.imgSize{
		margin-top: 20px;
		width: 50px;
		height: 50px;
		background-image: url('../assets/images/50.png');
		opacity: 1;
	}
	.headFont{
		font-size: 35px;
		color: #293860;
		font-family: Lato-Bold, sans-serif;
		margin-bottom: 15px;
		opacity: 1;
	}
	.contentFont{
		font-size: 20px;
		color: #000000;
		font-family: Lato, sans-serif;
		opacity: 1;
	}
	.imgMiracast{
		//margin-top: 10px;
		width: 50px;
		height: 50px;
		//background-image: url('../assets/images/iconfinder_ic_cast_connected_48px_352265.png')
	}
  }
  
  @media only screen and (min-width: 762px) {
		.body__background {
	  //background-color: lavender;
		background-image: url($image) !important;
		background-size: cover;
	  opacity: 1;
	}
	
	.boxSize{
		margin-top: 100px;
		max-width: 434px;
		height: 636px;
	}
	.box{
		//color: #F4F4F4;
		background-color: rgba(248, 248, 248, $opa);
		box-shadow: 2px,2px;	
		//opacity: 0.6;	
	}
	.imgSize{
		margin-top: 20px;
		width: 86px;
		height: 86px;
		background-image: url('../assets/images/86.png');
		opacity: 1;
	}
	.headFont{
		font-size: 49px;
		color: #293860;
		font-family: Lato-Bold, sans-serif;
		opacity: 1;
		margin-bottom: 25px;
	}
	.contentFont{
		font-size: 32px;
		color: #000000;
		font-family: Lato, sans-serif;
		opacity: 1;
	}
	.imgMiracast{
		//margin-top: 10px;
		width: 86px;
		height: 86px;
		//background-image: url('../assets/images/iconfinder_ic_cast_connected_48px_352265.png')
	}
  }
  @media only screen and (min-width: 1440px) {
		.body__background {
	  //background-color: red !important;
		background-image: url($image) !important;
		background-size: cover;
	  opacity: 1;
	}
	.boxSize{
		max-width: 616px;
		height: 586px;
	}
	.box{
		background-color: rgba(248, 248, 248, $opa);
		box-shadow: 2px,2px;	
		//opacity: 1;	
	}
	.imgSize{
		margin-top: 30px;
		width: 110px;
		height: 110px;
		background-image: url('../assets/images/110.png');
		opacity: 1;
	}
	.headFont{
		font-size: 55px;
		color: #293860;
		font-family: Lato-Bold, sans-serif;
		margin-bottom: 25px;
		opacity: 1;
	}
	.contentFont{
		font-size: 32px;
		color: #000000;
		font-family: Lato, sans-serif;
		opacity: 1;
	}
	.imgMiracast{
		margin-top: 30px;
		width: 110px;
		height: 110px;
		//background-image: url('../assets/images/iconfinder_ic_cast_connected_48px_352265.png')
	}

  }
  