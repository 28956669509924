.sidenav {
	height: 100%;
	width: 200px;
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	background-color: whitesmoke;
	overflow-x: hidden;
	padding-top: 100px;
	padding-left: 6px;
	padding-right: 6px;
  }

  .sidenav a {
	padding: 6px 6px 6px 10px;
	text-decoration: none;
	font-size: 14px;
	text-align: left;
	color: #818181;
	display: block;
  }

  .sidenav a:hover {
	color: #024347;
  }

  .main {
	margin-left: 200px; /* Same as the width of the sidenav */
	}

	.nav-item {
		display: block;
		background: #444;
		height: 100%;
		padding: 16px 25px 10px;
		color: #fff;
		outline: none;
		margin-left: 2px;
		text-transform: uppercase;
		font-size: 14px;
		font-weight: bold;
		border-bottom: 4px solid #444;
		box-sizing: border-box;

		&:hover {
			color: white;
			border-bottom: 4px solid #00d1b2;
		}
	}

	.info-item {
		height: 100%;
		padding: 16px 25px 10px;
		color: darkgray;
	}

	.is-danger {
		background: rgb(153, 9, 9) !important;
		border-bottom: 4px solid rgb(153, 9, 9);

		&:hover {
			background: rgba(204, 48, 48, 0.9) !important;
			border-bottom: 4px solid rgb(153, 9, 9);
		}
	}

	.navigation {
		margin-bottom: 50px;
		box-sizing: border-box;
	}

	.navbar {
		height: 100%;
		background: rgba($color: #ddd, $alpha: 0.8);
		box-sizing: border-box;
	}