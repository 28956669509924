.home {
  padding: 2rem;

  &__header {
    padding: 2rem 0;
  }

  /* &__content {

  } */
}
$opa: 0.75;
.home__error {
  margin-top: 1rem;
  padding: 0.5rem;
  color: red;
  border: 1px solid red;
  background: rgba(255, 0, 0, 0.15);
  border-radius: 8px;
}
h1{
  font-family: Lato-Bold, sans-serif;
  color: #293860;
  padding: 0px !important;
}
.boxBackground{
  background-color: rgba(248, 248, 248, $opa);
  max-width: 600px;
  margin-top: 80px;
}
