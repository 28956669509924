// Set your brand colors
$red: #ca4d4d;
$pink: #FA7C91;
$beige-light: #d1d1d1;
$white: #ffffff;

$grey: #606c76;

$color-danger: #ff3333;
$border-danger: #dd1111;

$color-primary: $red;
$color-secondary: $grey;
$white-bis: #dd1111;

$spinner__primary-color: $color-primary;

// Update Bulma's global variables
// $family-sans-serif: "Nunito", sans-serif;
$grey-light: $beige-light;
$primary: $red;
$link: $pink;
$danger: $color-danger;

// Update some of Bulma's component variables
$body-background-color: $white;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;
$input-focus-box-shadow-size: 0;
$input-focus-box-shadow-color: unset;
$input-focus-border-color: unset;
// $input-hover-border-color: unset;

