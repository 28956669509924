@font-face {
	font-family: "Lato-Bold";
	src: url("../assets/fonts/lato-bold/lato-bold.woff") format("woff"),
		 url("../assets/fonts/lato-bold/lato-bold.woff2") format("woff2"),
		 
}
@font-face {
	font-family: "Lato";
	src: url("../assets/fonts/lato-normal/lato-normal.woff") format("woff"),
		 url("../assets/fonts/lato-normal/lato-normal.woff2") format("woff2"),
		 
}