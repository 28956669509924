body {
    background: rgba($color: #eee, $alpha: 0.8) ;
   
// background: rgba(54,211,193,1);
// background: -moz-linear-gradient(top, rgba(54,211,193,1) 0%, rgba(108,209,58,1) 72%, rgba(54,211,193,1) 98%, rgba(54,211,193,1) 100%);
// background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(54,211,193,1)), color-stop(72%, rgba(108,209,58,1)), color-stop(98%, rgba(54,211,193,1)), color-stop(100%, rgba(54,211,193,1)));
// background: -webkit-linear-gradient(top, rgba(54,211,193,1) 0%, rgba(108,209,58,1) 72%, rgba(54,211,193,1) 98%, rgba(54,211,193,1) 100%);
// background: -o-linear-gradient(top, rgba(54,211,193,1) 0%, rgba(108,209,58,1) 72%, rgba(54,211,193,1) 98%, rgba(54,211,193,1) 100%);
// background: -ms-linear-gradient(top, rgba(54,211,193,1) 0%, rgba(108,209,58,1) 72%, rgba(54,211,193,1) 98%, rgba(54,211,193,1) 100%);
// background: linear-gradient(to bottom, rgba(54,211,193,1) 0%, rgba(108,209,58,1) 72%, rgba(54,211,193,1) 98%, rgba(54,211,193,1) 100%);
// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#36d3c1', endColorstr='#36d3c1', GradientType=0 );
}
.backBody{
    background: rgba($color: #eee, $alpha: 0.8) ;
}
h1{
    //background: lightgray;
    padding-left: 20px;
}
thead {
    background: gray;
}

tbody tr {
    background: lightgray;

    &:hover {
        background: #00d1b236 !important;
        cursor: default;
    }
}
.total{
    background-color: lightblue;
    font-size: 24px;
    height: 30px;
    width: 30px;
    
  }
.elements__Position{
    text-align: center;
    color: black;
  }
.dropdown{
    margin-top: 7px;
    margin-bottom: 3 px;
    background-color: lightblue;
    border-radius: 2em;
    padding-left:10px;
  }