h2{
  margin-top: 30%;
}
.error {
    border: 2px solid red;
  }
  
.input__search{
    width: 200px;
    height: 30px;
  }
.table__distanz{
    margin-top: 5px;
  }

.total{
    background-color: lightblue;
    font-size: 24px;
    height: 30px;
    width: 30px;
    
  }
.elements__Position{
    text-align: center;
    color: black;
  }
.dropdown{
    margin-top: 7px;
    margin-bottom: 3 px;
    background-color: lightblue;
    border-radius: 2em;
    padding-left:10px;
  }
