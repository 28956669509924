.validator-bubble {
    position:absolute;
    top:-10px;
    right:0;
    // border: 1px solid $color-danger;
    border-radius: 5px;
    background-color: $color-danger;
    color: #fff;
    font-size: 80%;
    padding: 5px 15px;
    font-weight:bold;
    animation-name: slidein;       
    animation-duration: 0.3s;
}
.validator-bubble:after {
    content:'';
    position: absolute;
    display:block;
    width:10px;
    height:10px;
    transform: rotate(45deg);
    margin-left:10px;
    background: $color-danger;
}

@keyframes slidein {
    from  {
        top: -30px;
    }
    to {
        top: -10;
    }
}