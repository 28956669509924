.home {
  padding: 2rem;

  &__header {
    padding: 2rem 0;
  }

  /*&__content {

  }*/
}

.home__error {
  margin-top: 1rem;
  padding: 0.5rem;
  color: red;
  border: 1px solid red;
  background: rgba(255, 0, 0, 0.15);
  border-radius: 8px;
}