$spinner__primary-color: #ccc;
$spinner__secondary-color: #00d1b2;

.spinner {
    font-size: 10px;
    margin: 50px auto;
    text-indent: -9999em;
    width: 11em;
    height: 11em;
    border-radius: 50%;
    background: #eee;
    // background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    // background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    // background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    // background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    // background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }

  .spinner:before {
    width: 50%;
    height: 50%;
    background: $spinner__secondary-color;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }

  .spinner:after {
    background: $spinner__primary-color;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  @-webkit-keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

// @import "../../styles/base/functions.scss";
// @import "../../styles/base/vars.scss";

// $spinner__primary-color: green;
// $spinner__secondary-color: transparent;

// .spinner {
//     height: 100px;
//     width: 100px;
//     display: inline-block;
//     text-indent: 100%;
//     overflow: hidden;
// }

// .spinner.is-hidden {
//     display: none;
// }

// .spinner::after {
//     content: '';
//     display: block;

//     border: get-vw(6px) solid $spinner__secondary-color;
//     border-radius: 50%;
//     border-top-color: $spinner__primary-color;
//     border-right-color: $spinner__primary-color;
//     border-bottom-color: $spinner__primary-color;

//     width: get-vw(46px);
//     height: get-vw(46px);
//     margin: auto;

//     animation: spinner 1s infinite linear;
// }

// .spinner--global {
//     position: fixed;

//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;

//     display: flex;
//     z-index: 1000;
// }

// @keyframes spinner {
//     0% {
//         transform: rotate(0deg);
//     }
//     100% {
//         transform: rotate(360deg);
//     }
// }

