/* @import '../components/header/style.scss';
@import '../components/adminNavigation/style.scss';

@import '../routes/activation/style.scss';
@import '../routes/admin/style.scss'; */


@import "~bulma/bulma.sass";
@import "./base/colors.scss";
@import "../components/spinner/style.scss";
@import "../routes/success/style.scss";
@import "../routes/home/style.scss";
@import "./validatorBubble.scss";
@import "../routes/admin/login/style.scss";
@import "../components/adminNavigation/style.scss";
@import "../routes/admin/devices/style.scss";
@import "../routes/admin/overview/style.scss";
@import "../routes/admin/licenses/style.scss";
@import "../routes/admin/requests/style.scss";
@import "../routes/admin/info/style.scss";

// Font Import

@import "fonts.scss";


html {
    box-sizing: border-box;
    // font-size:0.8rem;
    background: rgba($color: #eee, $alpha: 0.8) ;
}
body {
	color: $color-secondary;
	font-family: 'PT Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
	// font-size: 0.8rem; // Currently ems cause chrome bug misinterpreting rems on body element
	font-weight: 400;
	width: 100%;
    height: 100vh;
    
}
#root {
    height:100%;
    width:100%;
}

*, *:before, *:after {
    box-sizing: inherit;
}

h1,h2,h3,h4,h5 {
    font-weight: 700;
}
h1 { font-size: 2.5rem }
h2 { font-size: 2rem }
h3 { font-size: 1.7rem }
h4 { font-size: 1.4rem }
h5 { font-size: 1.2rem }

.image-cover {
    object-fit: cover;
}

.field {
    position: relative;
}

#app {
    width: 100%;
    height: 100%;
    position: relative;
}

.landing-page-background {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    pointer-events:none;
    z-index:-1;
    &__img {
        width:100%;
        height:calc(100% - 5px);
        object-fit: cover;
        object-position:bottom;
    }
}
